html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.headerTxtSection {
  text-align: center;
  color: white;
  background-color: black;
  width: 100vw;
  padding: 50px 0 58px 0;
  margin-top: 30px;
}

.headerTxtSection h1 {
  margin: 0;
  font-size: 2.7rem;
}

.firstLine {
  font-size: 2rem;
  font-weight: bold;
}

.secondLine {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 40px;
}

.algegoTop {
  text-align: center;
  padding-top: 30px;
}

.algegoTop img {
  width: 200px;
}

/* FIXED ARROW  AND ENVELOP */

.homeArrowShow,
.homeArrowHide,
.envelopShow {
  position: fixed;
  right: 20px;
  bottom: 120px;
  transition: 1s;
  opacity: 1;
  z-index: 1;
  /* filter: drop-shadow(0 0 .2rem black); */
}

.homeArrowHide {
  opacity: 0;
}

.homeArrowShow img,
.homeArrowHide img,
.envelopShow img {
  width: 65px;
}

.envelopShow {
  bottom: 40px;
}

/* TIMELINE */
.timeLine {
  padding-top: 150px;
  padding-bottom: 120px;
}

.zoneTimeLine {
  background-color: #f8f8f8;
}

.iconClass svg {
  fill: white;
}

.imageOfproject {
  display: flex;
  justify-content: center;
}

.imageOfproject div img {
  width: 300px;
  margin-top: 30px;
}

.desjardins svg path {
  fill: #107B48;
}

.algego svg path {
  fill: black;
  width: 300px;
}

.algego svg,
.desjardins svg {
  width: 40px;
  height: 40px;
  top: 37%;
  left: 37%;
}

.mandat p,
.client p {
  margin: 0;
  line-height: 1.3rem;
}

.mandat {
  margin-bottom: 30px;
}

.client {
  margin-bottom: 40px;
}

.paragraph2 {
  margin-top: 20px !important;
}

hr {
  /* border-color: black; */
  border-color: #E6E7E9;
  margin: 30px 0;
  border-width: 2px;
}

.date {
  text-align: left;
  font-weight: bold !important;
  font-size: 4rem !important;
  padding-top: 0 !important;
  opacity: 1 !important;
}

.date1 {
  color: #e76f51;
}

.date-B {
  color: white;
  margin-bottom: 20px;
  font-size: 4.2rem !important;
  display: none;
}

.date2 {
  color: #21867a;
}

.date3 {
  color: #5163e7;
}

.date4 {
  color: #5f170d;
}

.date5 {
  color: #5d316b;
}

.date6 {
  color: #b92d18;
}

.date7 {
  color: #274653;
}

.date8 {
  color: #842bc2;
}

.date9 {
  color: #766018;
}

.vertical-timeline-element-date {
  top: -12px !important;
}

.titreProjet h1 {
  font-size: 2rem;
}

.iframeWrapper {
  width: 100%;
}

.iframeWrapper img {
  width: 100% !important;
  margin-top: 0 !important;
}

h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.emptyDiv {
  display: none;
}

/* Footer start */
.footerWrapper {
  background-color: black;
  color: white;
  display: flex;
  padding: 30px;
  justify-content: center;
}

.footerInner {
  width: 600px;
}

#footerLogo img {
  width: 80px;
  margin: 0 2rem;
}

#footerLogo {
  text-align: center;
}

.footerTxt {
  margin-top: -150px;
  margin: 1rem;
  font-family: 'Quicksand', sans-serif;
  font-size: .8rem;
}

.footerWrapper p {
  font-family: 'Quicksand', sans-serif;
  font-size: .85rem;
  margin: 0;
}

.footerTxtLeft {
  text-align: right;
}

.footerWrapper strong {
  font-size: 1.1rem;
}

.footerTxtRight {
  text-align: left;
}

.footerHide {
  display: none;
}

/* Footer end */

@media only screen and (max-width:1169px) {

  .algego svg,
  .desjardins svg {
    width: 32px;
    height: 32px;
  }
}

@media only screen and (max-width:575px) {

  .footerTxtLeft,
  .footerTxtRight {
    text-align: center;
    margin: 0;
  }

  .footerHide {
    display: block;
  }

  .footerShow {
    display: none;
  }

  .footerTxt {
    text-align: center;
  }
}

@media only screen and (max-width:1169px) {
  .date-B {
    display: block;
  }

  .emptyDiv {
    display: block;
    height: 125px;
  }
}